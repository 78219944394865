import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'next-i18next';

const LoaderSpin = function ({ size = 'default', inline = false }) {
  const { t } = useTranslation('common');
  const antIcon = (
    <LoadingOutlined style={{ fontSize: size === 'default' ? 48 : 32 }} spin />
  );
  return (
    <div
      style={{
        maxWidth: '100vw',
        height: inline ? 'initial' : '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin indicator={antIcon} />
      <p style={{ fontSize: size === 'default' ? 16 : 14, margin: 16 }}>
        {t('loading')}
      </p>
    </div>
  );
};

export default LoaderSpin;

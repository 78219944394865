import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { setCookie, destroyCookie } from 'nookies';
import { set } from 'js-cookie';

const Invitation = dynamic(() => import('./Invitation'));
const Inscription = dynamic(() => import('./Inscription'));
const Password = dynamic(() => import('./Password'));
const IP = dynamic(() => import('./IP'));

const Confidentiality = function ({
  errorData,
  contentId,
  contentType,
  children
  // setToken,
  // setPassword,
}) {
  const [privacy, setPrivacy] = useState();
  const [title, setTitle] = useState();
  const [logo, setLogo] = useState();
  const [color, setColor] = useState();
  const [tokenError, setTokenError] = useState();
  const [domainError, setDomainError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [additionnalFields, setAdditionnalFields] = useState([]);

  useEffect(() => {
    if (!errorData) {
      setPrivacy('public');
      return;
    }
    if (errorData.isInvitation) {
      setPrivacy('invitation');
    } else if (errorData.isInscription) {
      setPrivacy('inscription');
    } else if (errorData.isPassword) {
      setPrivacy('password');
    } else if (errorData.isIp) {
      setPrivacy('ip');
    } else {
      setPrivacy('public');
    }

    const {
      contentName,
      contentLogo,
      contentColor,
      isTokenWrong,
      isDomainWrong,
      isPasswordWrong,
      fields
    } = errorData;

    contentName && setTitle(contentName);
    contentLogo && setLogo(contentLogo);
    contentColor && setColor(contentColor);
    fields?.length && setAdditionnalFields(fields);

    if (isTokenWrong) {
      setTokenError(true);
      destroyCookie(null, 'contentToken');
    }

    if (isDomainWrong) {
      setDomainError(true);
      destroyCookie(null, 'contentToken');
    }

    if (isPasswordWrong) {
      setPasswordError(true);
      destroyCookie(null, 'contentPassword');
    }
  }, [errorData]);

  useEffect(() => {
    setCookie(null, 'contentToShowId', contentId, {
      maxAge: 3 * 60 * 60, // 3 hours
      path: '/',
      sameSite: 'None',
      secure: true
    });
    setCookie(null, 'contentToShowType', contentType, {
      maxAge: 3 * 60 * 60, // 3 hours
      path: '/',
      sameSite: 'None',
      secure: true
    });
  }, [contentId, contentType]);

  return (
    <>
      {privacy === 'public' && children}
      {privacy === 'invitation' && (
        <Invitation
          tokenError={tokenError}
          logo={logo}
          color={color}
          title={title}
        />
      )}
      {privacy === 'inscription' && (
        <Inscription
          tokenError={tokenError}
          logo={logo}
          color={color}
          title={title}
          domainError={domainError}
          fields={additionnalFields}
        />
      )}
      {privacy === 'password' && (
        <Password
          logo={logo}
          color={color}
          title={title}
          passwordError={passwordError}
        />
      )}
      {privacy === 'ip' && <IP logo={logo} color={color} title={title} />}
    </>
  );
};

export default Confidentiality;
